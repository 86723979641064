<template>
  <div class="invest-result" :class="{skeleton : !state.loaded }">
    <!--    {{ state.project }}-->
    <!--    {{ state.subscribe }}-->
    <template v-if="$store.state.account.loggedIn">
      <div class="container">
        <div class="name font-xxl">
          <router-link :to="`/invest/${$route.params.projectSeq}`" title="프로젝트 조회">
            <b>{{ state.project.projectName }}</b>
          </router-link>
        </div>
        <div class="txt font-lg">
          <span>프로젝트에 투자해주셔서 감사합니다.</span>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <table class="table">
              <tbody>
              <tr>
                <th>
                  <span>회사명</span>
                </th>
                <td>
                  <span>{{ state.project.corporateName }}</span>
                </td>
              </tr>
              <tr>
                <th>
                  <span>투자 종목</span>
                </th>
                <td>
                  <span>{{ state.project.stockTypeText }}</span>
                </td>
              </tr>
              <tr>
                <th>
                  <span>목표 금액</span>
                </th>
                <td>
                  <span>{{ $lib.getNumberFormat(state.project.totAmount) }}</span>
                </td>
              </tr>
              <tr>
                <th>
                  <span>최소 투자 금액</span>
                </th>
                <td>
                  <span>
                    <span>{{ $lib.getNumberFormat(state.project.minAmount) }}원</span>
                    <span>({{ $lib.getNumberFormat(state.project.minCount) }}</span>
                    <span>{{ state.project.stockTypeText === "주식" ? "주" : "좌" }})</span>
                  </span>
                </td>
              </tr>
              <tr>
                <th>
                  <span>펀딩 종료일</span>
                </th>
                <td>
                  <span>{{ state.project.fundingEndDate }}</span>
                </td>
              </tr>
              <tr>
                <th>
                  <span>배정 예정일</span>
                </th>
                <td>
                  <span>{{ state.project.distriDate }}</span>
                </td>
              </tr>
              <tr>
                <th>
                  <span>투자일</span>
                </th>
                <td>
                  <span>{{ state.subscribe.createDate }}</span>
                </td>
              </tr>
              <tr>
                <th>
                  <span>투자 금액</span>
                </th>
                <td class="color-point">
                  <span>
                    <b>{{ $lib.getNumberFormat(state.subscribe.subscrAmount) }}원</b>
                    <span>({{ $lib.getNumberFormat(state.subscribe.subscrCount) }}</span>
                    <span>{{ state.project.stockTypeText === "주식" ? "주" : "좌" }})</span>
                  </span>
                </td>
              </tr>
              <tr v-if="state.project.stockStorageType === '01'">
                <th>
                  <span>증권 계좌</span>
                </th>
                <td>
                  <span v-if="Number(state.subscribe.secucoAcopno) === 20">(N/A)</span>
                  <span v-else>
                    <span class="d-inline-block">{{ $definitions.codes.obj.securitiesCompany[state.subscribe.secucoAcopno] }}</span>
                    <span>&nbsp;</span>
                    <span class="d-inline-block">{{ state.subscribe.invstrTrsacNo }}</span>
                  </span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="col-lg-6 illustration">
            <span class="img" style="background-image: url(/assets/img/page.invest.done.svg)"></span>
            <div class="sns">
              <button class="btn img facebook" title="페이스북으로 공유" @click="share('facebook')" style="background-image: url(/assets/ico/common.sns.facebook.svg)"></button>
              <button class="btn img twitter" title="트위터로 공유" @click="share('twitter')" style="background-image: url(/assets/ico/common.sns.twitter.svg)"></button>
              <button class="btn img" title="카카오톡으로 공유" @click="share('kakaolink')" style="background-image: url(/assets/ico/common.sns.kakaotalk.svg)"></button>
              <button class="btn img" title="밴드로 공유" @click="share('band')" style="background-image: url(/assets/ico/common.sns.band.svg)"></button>
            </div>
          </div>
        </div>
        <div class="act">
          <router-link to="/mypage/participant/invest" class="btn btn-bordered-point font-sm">마이페이지에서 확인하기</router-link>
        </div>
        <ul class="guide bottom tight font-sm">
          <li>
            <b>안내해드립니다.</b>
          </li>
          <template v-if="state.project.stockStorageType === '01'">
            <li>
              <span>증권계좌번호를 입력하지 않으신 경우 마이페이지에서 증권계좌(종합위탁계좌)번호를 입력해주시기 바랍니다.</span>
            </li>
            <li>
            <span>
              <span>증권계좌 유효성 확인결과 및 증권계좌 정보 수정은 </span>
              <span>
                <router-link to="/mypage/participant/invest">
                  <b>마이페이지</b>
                </router-link>
                <span>에서 확인가능합니다.</span>
              </span>
            </span>
            </li>
          </template>
          <li>
            <span>
              <span>출금계좌는 </span>
              <span>마이페이지 > </span>
              <router-link to="/mypage/investor-account-info">투자 계좌</router-link>
              <span>에서 변경 가능합니다.</span>
            </span>
          </li>
        </ul>
      </div>
    </template>
    <Loading :opaque="true" v-else-if="$store.state.account.checked"/>
  </div>
</template>

<script>
import {defineComponent, onUnmounted, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import Number from "@/components/Number";
import Phone from "@/components/Phone";
import Address from "@/components/Address";
import router from "@/scripts/router";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";
import store from "@/scripts/store";
import Confetti from "@/components/Confetti";
import caches from "@/scripts/caches";
import definitions from "@/scripts/definitions";
import Loading from "@/components/Loading.vue";

function Component(initialize) {
  this.name = "pageOrderInvest";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Loading, Confetti, Address, Phone, Number},
  setup() {
    const component = new Component(async () => {
      if (!store.state.account.loggedIn) {
        return store.dispatch("goLoginPage");
      }

      const subscribeSeq = router.app.$route.params.subscribeSeq;

      // http 캐시 초기화
      caches.resetHttps();

      if (!router.app.$route.query.modals) {
        state.timeout = setTimeout(() => {
          state.project.memberSeq && store.commit("openModal", {
            name: "Follow",
            params: {
              memberSeq: state.project.memberSeq,
            }
          });
        }, 2000);
      }

      await store.dispatch("initSnsShare");

      state.loaded = false;
      const res = await http.get(`/api/invest/project/${projectSeq}/subscribe/${subscribeSeq}`).catch(httpError(() => {
        router.push({path: `/invest/${projectSeq}`});
      }));
      state.loaded = true;

      if (res?.error) {
        return;
      }

      state.project = res.data.body.project;
      state.subscribe = res.data.body.subscribe;
      await store.dispatch("setDocumentTitle", state.project.projectName);

      if (Array.isArray(res.data.body.thumbNailList) && res.data.body.thumbNailList.length) {
        state.project.thumbNailPath = res.data.body.thumbNailList[0]?.thumbFilePath;
      }
    });

    const state = reactive({
      loaded: false,
      timeout: 0,
      project: {
        projectName: "Wait a moment",
        builderName: "Wait a moment",
        thumbNailPath: "",
        stockStorageType: "",
      },
      subscribe: {}
    });

    const projectSeq = router.app.$route.params.projectSeq;

    const share = (platform) => {
      const url = location.origin + `/invest/${projectSeq}`;
      const title = state.project.projectName;
      const desc = state.project.simpleText;
      const imgUrl = store.getters.thumbnailUrl(state.project.thumbNailPath);
      const imgWidth = definitions.thumbnail.width;
      const imgHeight = definitions.thumbnail.height;
      store.dispatch("share", {platform, title, desc, url, imgUrl, imgWidth, imgHeight});
    };

    onUnmounted(() => {
      clearTimeout(state.timeout);
    });

    return {component, state, share};
  }
});
</script>

<style lang="scss" scoped>
.invest-result {
  padding: $px50 0;

  .container {
    max-width: 767px;

    .name {
      padding-bottom: $px8;

      span, b {
        vertical-align: top;
      }

      b {
        display: inline-block;
        width: $px420;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 600;
        max-width: 100%;
      }
    }

    .row > div {
      table {
        margin-top: $px20;
        margin-bottom: $px37;
        table-layout: fixed;
        width: auto;

        th, td {
          border: 0;
        }

        th {
          padding-left: 0;
          padding-right: $px35;
          width: $px135;
        }
      }

      &.illustration {
        text-align: center;

        > .img {
          width: $px400;
          height: $px373;
        }

        .sns {
          padding-top: $px17;
          padding-bottom: $px25;

          .btn {
            width: $px40;
            height: $px40;
            margin: $px5;
            box-shadow: none;
            border-radius: 50%;
            background-position: 50%;

            &.facebook {
              background-color: #445d98;
            }

            &.twitter {
              background-size: 120%;
              background-color: #2aade3;
            }
          }
        }
      }
    }

    .act {
      text-align: center;
      padding-bottom: $px50;

      > .btn {
        padding: $px15 $px25;
      }
    }

    .guide {
      background: $colorBackground;
      padding: $px25;
      border: $px1 solid #eee;
      border-radius: $px4;

      li {
        position: relative;
        padding-left: $px10;
        margin-bottom: $px5;

        &:first-child {
          padding-left: 0;
          margin-bottom: $px15;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &:not(:first-child):before {
          content: "-";
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }

  &.skeleton {
    .container {
      .name b, .txt > span {
        @include skeleton;
      }

      .row > div {
        table {
          th, td {
            > span {
              @include skeleton;
            }
          }
        }

        &.illustration {
          .img {
            @include skeleton;
          }
        }
      }

      .act .btn {
        @include skeleton;
      }

      .guide > li {
        padding-left: 0;

        > b, > span {
          @include skeleton;
        }

        &:before {
          content: "";
        }
      }

      .sns button {
        @include skeleton;
      }
    }
  }

  @media(max-width: 767px) {
    padding-left: $px20;
    padding-right: $px20;

    .container {
      .row > div {
        table {
          margin-bottom: $px25;

          th, td {
            padding-top: $px10;
            padding-bottom: $px10;
          }
        }

        &.illustration > .img {
          width: $px261;
          height: $px243;
        }
      }

      .act {
        padding-bottom: $px30;
      }
    }
  }
}
</style>